// extracted by mini-css-extract-plugin
export var corner = "layout-module--corner--16b38";
export var currentIndicator = "layout-module--currentIndicator--902dc";
export var gardenCaret = "layout-module--gardenCaret--c7e2c";
export var gardenDropdown = "layout-module--gardenDropdown--02dad";
export var gardenEmojis = "layout-module--gardenEmojis--cb03f";
export var header = "layout-module--header--4755c";
export var logo = "layout-module--logo--ff776";
export var menuContent = "layout-module--menuContent--f5e33";
export var menuItem = "layout-module--menuItem--5c914";
export var nav = "layout-module--nav--7fd4b";
export var pageLink = "layout-module--pageLink--d730d";